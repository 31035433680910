<template>
  <div>
    <Card>
      <template #title>Consignatárias</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="consignatarias"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['cnpj', 'razaoSocial', 'nomeFantasia']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignatárias"
          responsiveLayout="scroll">
          <template #empty> Nenhuma consignatária encontrada. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormConsignataria()" />
          </template>

          <Column field="cnpj" header="CNPJ"></Column>
          <Column field="razaoSocial" header="Razão Social"></Column>
          <Column field="nomeFantasia" header="Nome Fantasia"></Column>
          <Column header="Status">
            <template #body="{ data }">
              <Chip
                v-if="data.ativo"
                label="ATIVO"
                class="mr-2 mb-2 custom-chip emUso" />
              <Chip
                v-else
                label="INATIVO"
                class="mr-2 mb-2 custom-chip foraUso" />
            </template>
          </Column>
          <Column header="Ações">
            <template #body="{ data }">
              <Button
                v-tooltip="'Rubricas'"
                icon="pi pi-money-bill"
                class="p-button-rounded p-button-success mr-2"
                @click="rubrica(data)" />

              <Button
                v-if="$auth.hasRoleGestao()"
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="showFormConsignataria(data)" />

              <Button
                v-if="$auth.hasRoleGestao()"
                v-tooltip.left="'Contratos'"
                icon="pi pi-id-card"
                class="p-button-rounded p-button-submit mr-2"
                @click="contratos(data)" />
              <!-- <Button
                  v-tooltip.left="'Excluir'"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger mr-2"
                  @click="confirmDeleteConsignataria(slotProps.data)" /> -->
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <Dialog
      modal
      header="Header"
      :style="{ width: '350px' }"
      :visible.sync="deleteConsignatariaDialog">
      <template #header>
        <h3>Excluir Consignataria</h3>
      </template>

      Confirma a exclusão de {{ consignataria.nome_fantasia }} ?

      <template #footer>
        <Button
          label="Não"
          icon="pi pi-times"
          class="p-button-text"
          @click="deleteConsignatariaDialog = false" />
        <Button
          label="Sim"
          icon="pi pi-check"
          autofocus
          @click="deleteConsignataria" />
      </template>
    </Dialog>

    <ConsignatariaFormDialog
      :visible.sync="showConsignatariaFormDialog"
      :proposconsignataria="consignataria"
      :update="update"
      @onUpdate="onUpdateConsignataria" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ConsignatariaFormDialog from './ConsignatariaFormDialog.vue'
import ConsignatariaService from '@/service/ConsignatariaService.js'

export default {
  components: {
    ConsignatariaFormDialog,
  },

  data() {
    return {
      filtros: {},
      consignatarias: null,
      consignataria: {},
      deleteConsignatariaDialog: false,
      loading: false,
      showConsignatariaFormDialog: false,
      update: false,
    }
  },

  created() {
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      if (this.$auth.hasRoleGestao()) {
        this.consignatariaService.getConsignatarias().then((res) => {
          this.consignatarias = res
          this.loading = false
        })
      } else if (this.$auth.hasRoleGestaoEntidade()) {
        this.consignatariaService
          .getConsignatariasUsuarioGestaoEntidade()
          .then((res) => {
            this.consignatarias = res
            this.loading = false
          })
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    confirmDeleteConsignataria(data) {
      this.deleteConsignatariaDialog = true
      this.consignataria = data
    },

    showFormConsignataria(data) {
      if (data) {
        this.consignataria = data
        this.update = true
      } else {
        this.consignataria = {}
        this.update = false
      }
      this.showConsignatariaFormDialog = true
    },

    onUpdateConsignataria(event) {
      let index = this.consignatarias.findIndex((el) => el.id === event.id)
      index != -1
        ? this.$set(this.consignatarias, index, event)
        : this.consignatarias.unshift(event)
    },

    deleteConsignataria() {
      this.deleteConsignatariaDialog = false
      this.consignatariaService
        .deleteConsignataria(this.consignataria)
        .then(() => {
          this.carregarDados()
          this.consignataria = {}
          this.$toast.add({
            severity: 'success',
            summary: 'Excluido com sucesso!',
            life: 10000,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    rubrica(data) {
      this.$router.push({
        name: 'consignataria-rubricas',
        params: { idConsignataria: data.id },
      })
    },

    contratos(data) {
      this.$router.push({
        name: 'consignataria-contratos-parcelas',
        params: { idConsignataria: data.id },
      })
    },
  },
}
</script>

<style>
.foraUso {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}
.emUso {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}
</style>
